.footer {
  margin-top: 50px;
  * {
    text-align: center;
  }

  p {
    margin: 2px 0;
    font-size: 12px;
    color: $gray;
  }

  h2 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400 !important;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 4px 0;

      a {
        color: $gray;
      }

      a:hover {
        color: $blue-color;
      }
    }
  }
}
