pre.raw {
  border: 1px solid $gray;
  border-radius: 5px;
  padding: 15px;
  overflow-x: scroll;
  background-color: #fff;
}

button.raw {
  float: right;
}
