.container {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.column {
  flex: 1;
  padding: 10px 20px;
}

.column-6 {
  max-width: 50%;
}

.column-3 {
  max-width: 25%;
  flex: 3
}

.column-4 {
  max-width: 33.3333333%;
  flex: 4
}

.column-9 {
  max-width: 75%;
  flex: 9;
}
